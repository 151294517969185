import { reactive } from "vue";
import HttpService from "./http.service";

export const CategoriesService = reactive({
    state: [],
    load() {
        this.get({
            fields: 'id,name,parent,position',
            order: 'position',
            size: 9999
        }).then(response => this.state = response.data);
    },
    get(data) {
        return HttpService.get('categories/all', data);
    },
    save(data) {
        return HttpService.post('categories/save', data);
    }
})

CategoriesService.load();