<template>
    <div class="upload">
        <div class="uploader" @click="onUploaderClick">
            Выбрать файлы
            <input
                ref="input"
                type="file"
                multiple
                @change="onChange($event)"
            >
        </div>
        <div class="preview-list" v-if="items">
            <div
                class="preview-item"
                v-for="(item, index) in items"
                :key="index"
            >
                <div class="picture">
                    <img alt="" :src="getItemSrc(item)">
                </div>
                <div class="name">{{ item.name }}</div>
                <div class="actions">
                    <div @click="onRemoveItem(index)">удл</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Upload",
    props: {
        val: Array
    },
    methods: {
        onUploaderClick() {
            this.$refs.input.click();
        },
        onChange(event) {
            if (event.target.files.length > 0) {
                for (let i = 0; i < event.target.files.length; i++) {
                    this.items.push(event.target.files[i]);
                }
            }

            this.$emit('upload', this.items);
        },
        onRemoveItem(index) {
            if (!(this.items[index] instanceof File)) {
                this.remove.push(this.items[index]);
                this.$emit('remove', this.remove);
            }
            this.items.splice(index, 1);
        },
        getItemSrc(item) {
            if (item instanceof File && item.type.indexOf('image') !== -1) {
                return URL.createObjectURL(item);
            }
            if (typeof item === 'object' && item.value) {
                return item.value;
            }
            return '';
        }
    },
    watch: {
        val(value) {
            this.items = value;
        }
    },
    data() {
        return {
            items: [],
            remove: []
        }
    },
    created() {
        this.items = this.val;
    }
}
</script>