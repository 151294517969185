<template>
    <listing
        :items="items"
        :pagination="pagination"
        :fields="fields"
        @reset-filters="resetFilters"
    >
        <template v-slot:advanced-filters>
            <div class="advanced-filter" v-if="dictionariesService.tags">
                <div class="label">Теги</div>
                <div class="options">
                    <div
                        class="option"
                        v-for="(tag,index) in dictionariesService.tags"
                        :key="index"
                        :class="{'is-active' : isActiveTag(tag)}"
                        @click="toggleTag(tag)"
                    >
                        {{ tag.name }}
                    </div>
                </div>
            </div>
        </template>
        <template v-slot:head>
            <th>Название товара</th>
            <th>Категория</th>
            <th>Теги</th>
        </template>
        <template v-slot:row="data">
            <td>{{ data.item['name'] }}</td>
            <td>{{ data.item['category_name'] }}</td>
            <td>
                <div class="tags">
                    <div class="tag" v-for="tag in data.item['tags']" :key="tag.id">
                        {{ tag.name }}
                    </div>
                </div>
            </td>
        </template>
    </listing>
</template>

<script>

import Listing from "../listing/Listing";
import { DictionariesService } from "../../services/dictionaries.service";

export default {
    name: "CatalogListing",
    components: {Listing},
    props: {
        items: Array,
        fields: Array,
        pagination: Object,
    },
    methods: {
        toggleTag(selectedTag) {
            const index = this.tags.findIndex(tag => tag.id === selectedTag.id);
            if (index !== -1) {
                this.tags.splice(index, 1);
            } else {
                this.tags.push(selectedTag);
            }
            this.changeTags();
        },
        isActiveTag(selectedTag) {
            return !!this.tags.find(tag => tag.id === selectedTag.id);
        },
        resetFilters() {
            this.tags = [];
            this.changeTags();
        },
        changeTags() {
            localStorage.setItem('filters[catalog][tags]', JSON.stringify(this.tags));
            this.$emit('changeFilterTags', this.tags);
        },
    },
    created() {
        const storageTags = localStorage.getItem('filters[catalog][tags]');
        if (storageTags) {
            this.tags = JSON.parse(storageTags);
            this.$emit('changeFilterTags', this.tags);
        }
    },
    data() {
        return {
            tags: [],
            dictionariesService: DictionariesService,
            selected: [],
            query: ''
        }
    }
}
</script>

<style scoped>

</style>