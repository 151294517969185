<template>
    <div v-if="init"></div>
    <div class="box">
        <div class="drag-zones first-zone">
            <div
                v-if="!disableZone && dragItem !== 'first' + parent"
                @dragleave="onDragLeave"
                @dragenter="onDragEnter($event, 'first' + parent)"
                @dragover.prevent
                @drop="onDrop($event, 1, parent)"
                class="drag-zone"
                :class="{'is-active' : 'first' + parent === this.dropZone}"
            >
            </div>
            <div class="add" @click="addItem(1, parent)"></div>
        </div>
        <div
            v-for="item in getItemsByParent(parent)"
            :key="item.id"
            :class="{
                'is-drag' : dragItem === item.id,
                'has-child' : getItemsByParent(item.id).length > 0,
                'is-open' : !!this.openItems[item.id]
            }"
            class="level"
        >
            <div class="open-trigger" @click="toggleItem(item.id)"></div>
            <div
                class="item"
                @dragstart="onDragStart($event, item)"
                @dragend="onDragEnd"
                @dragleave="onDragLeave"
                @dragenter="onDragEnter($event, item.id)"
                @dragover.prevent
                @drop="onDrop($event, item.position, parent)"
                draggable="true"
            >
                <div class="move-icon"></div>
                <input type="text" v-model="item.name" @input="changeItems">
                <div class="actions">
                    <span class="mini-button-box" @click="removeItem(item.id)">удл</span>
                </div>
            </div>
            <div class="box" v-if="getItemsByParent(item.id).length === 0">
                <div class="level is-empty">пусто</div>
                <div class="drag-zones">
                    <div
                        v-if="!disableZone && item.id !== this.dragItem"
                        @dragleave="onDragLeave"
                        @dragenter="onDragEnter($event, 'parent-' + item.id)"
                        @dragover.prevent
                        @drop="onDrop($event, 1, item.id)"
                        class="drag-zone"
                        :class="{'is-active' : 'parent-' + item.id === this.dropZone}"
                    ></div>
                    <div class="add" @click="addItem(1, item.id)"></div>
                </div>
            </div>
            <categories-editor-tree
                v-if="getItemsByParent(item.id).length > 0"
                :init="false"
                :items="items"
                :parent="item.id"
                :disable-zone="localDisableZone && item.id === dragItem"
                @remove="removeItem"
                @change="changeItems"
            ></categories-editor-tree>
            <div class="drag-zones">
                <div
                    v-if="!disableZone && item.id !== this.dragItem"
                    @dragleave="onDragLeave"
                    @dragenter="onDragEnter($event, item.id)"
                    @dragover.prevent
                    @drop="onDrop($event, item.position, parent)"
                    class="drag-zone"
                    :class="{'is-active' : item.id === this.dropZone}"
                >
                </div>
                <div
                    v-if="!disableZone && getItemsByParent(item.id).length === 0 && item.id !== this.dragItem"
                    @dragleave="onDragLeave"
                    @dragenter="onDragEnter($event, 'child-' + item.id)"
                    @dragover.prevent
                    @drop="onDrop($event, item.position, item.id)"
                    class="drag-zone is-child"
                    :class="{'is-active' : 'child-' + item.id === this.dropZone}"
                ></div>
                <div class="add" @click="addItem(item.position, parent)"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "CategoriesEditorTree",
    emits: ['remove', 'change'],
    props: {
        init: {
            type: Boolean,
            default: true
        },
        parent: {
            type: [Number, String],
            default: 0
        },
        disableZone: {
            type: Boolean,
            default: false
        },
        items: Array
    },
    data() {
        return {
            list: [],
            dropZone: 0,
            dragItem: 0,
            localDisableZone: false,
            openItems: []
        }
    },
    methods: {
        onDragStart(evt, item) {
            this.localDisableZone = true;
            this.dragItem = item.id;
            evt.dataTransfer.dropEffect = 'move';
            evt.dataTransfer.effectAllowed = 'move';
            evt.dataTransfer.setData('item', item.id)
        },
        onDragEnter(evt, id) {
            setTimeout(() => {
                this.dropZone = id;
            }, 1);
        },
        onDragLeave() {
            this.dropZone = 0;
        },
        onDragEnd() {
            this.localDisableZone = false;
            this.dragItem = 0;
        },
        onDrop(evt, position, parent) {
            if (!this.disableZone) {
                const itemMove = this.list.find(item => item.id == evt.dataTransfer.getData('item'));
                if (itemMove) {
                    itemMove.parent = parent;
                    itemMove.position = position + 1;
                    this.openItems[parent] = true;
                    this.dropZone = 0;
                    this.getItemsByParent(this.parent).map(item => {
                        if (item.id !== itemMove.id && item.position >= itemMove.position) {
                            item.position++;
                        }
                    });
                }
            }
        },
        getItemsByParent(parent) {
            return this.list
                ? this.list.filter(item => item.parent === parent).sort((a, b) => {
                    if (a.position > b.position) {
                        return 1;
                    }
                    if (a.position < b.position) {
                        return -1;
                    }
                    return 0;
                })
                : [];
        },
        toggleItem(id) {
            this.openItems[id] = !this.openItems[id];
        },
        addItem(position, parent) {
            let newItem = {
                id: 'parent-' + Math.random(),
                parent: parent,
                name: '',
                position: position + 1,
                new: true
            };
            this.list.push(newItem);
            this.getItemsByParent(this.parent).map(item => {
                if (item.id !== newItem.id && item.position >= newItem.position) {
                    item.position++;
                }
            });
        },
        removeItem(id) {
            if (this.list.find(item => item.id === id).new !== true) {
                this.$emit('remove', id);
            }
            this.list = this.list.filter(item => item.id !== id);
        },
        changeItems() {
            this.$emit('change', this.list);
        }
    },
    watch: {
        items(value) {
            this.list = value;
        },
        list() {
            this.changeItems();
        }
    },
    mounted() {
        this.list = this.items;
    }
}
</script>