<template>
    <modal ref="categoriesEditor">
        <categories-editor
            :items="categoriesEditorItems"
            @close="closeCategoriesEditor"
            @save="saveCategories"
        ></categories-editor>
    </modal>
    <modal ref="editor">
        <catalog-editor
            :id="editor.id"
            :title="editor.title"
            :item="editor.item"
            :stores="editor.stores"
            :prices="editor.prices"
            :options="editor.options"
            @close="closeEditor"
            @item-saved="itemSaved"
        ></catalog-editor>
    </modal>
    <div class="sidebar">
        <div class="title with-button">
            <div @click="selectCategory(0)">Категории</div>
            <span class="mini-button-box" @click="openCategoriesEditor">ред</span>
        </div>
        <div class="categories custom-scroll">
            <categories-tree
                :items="categoriesService.state"
                :parent="0"
                :editable="true"
                :additional-links="additionalCategories"
                @selectCategory="selectCategory"
            ></categories-tree>
        </div>
    </div>
    <div class="content">
        <div class="title">Каталог</div>
        <catalog-listing
            :loading="loading"
            :items="listing.items"
            :pagination="listing.pagination"
            :fields="listing.fields"
            @change-page="changePage"
            @add-item="addItem"
            @edit-item="editItem"
            @remove-item="removeItem"
            @change-filter-tags="changeFilterTags"
            @apply-filters="loadItems"
            @close-filters="loadItems"
            @change-query="changeQuery"
        >
        </catalog-listing>
    </div>
</template>
<script>
import { CategoriesService } from "../services/categories.service";
import { CatalogService } from "../services/catalog.service";
import CategoriesTree from "../components/catalog/CategoriesTree";
import CatalogListing from "../components/catalog/CatalogListing";
import CategoriesEditor from "../components/catalog/CategoriesEditor";
import CatalogEditor from "../components/catalog/CatalogEditor";
import Modal from "../components/Modal";

export default {
    components: {
        CatalogEditor, CategoriesEditor, Modal, CatalogListing, CategoriesTree
    },
    methods: {
        itemSaved() {
            this.loadItems();
            this.closeEditor();
        },
        selectCategory(id) {
            this.listing.category = id;
            this.loadItems();
        },
        changePage(page) {
            this.listing.page = page;
            this.loadItems();
        },
        loadItems() {
            this.loading = true;
            this.catalogService.get(this.params).then(response => {
                this.listing.items = response.data;
                this.listing.pagination = response.pagination;
                this.loading = false;
            })
        },
        addItem() {
            this.editor.id = null;
            this.editor.title = 'Добавить товар';
            this.catalogService.dictionaries().then(response => {
                this.editor.stores = response.stores;
                this.editor.prices = response.prices;
                this.editor.options = response.options;
                this.editor.item = {
                    category: this.listing.category,
                    name: '',
                    stores: {},
                    prices: {},
                    options: {},
                    images: []
                }
            });
            this.$refs.editor.show();
        },
        editItem(id) {
            this.editor.id = id;
            this.catalogService.one({
                filters: {
                    id: id
                }
            }).then(response => {
                this.editor.errors = [];
                this.editor.item = response.data;
                this.editor.stores = response.stores;
                this.editor.prices = response.prices;
                this.editor.options = response.options;
                this.$refs.editor.show();
            });
        },
        removeItem(id) {
            this.catalogService.remove({
                filters: {
                    id: id
                }
            }).then(response => {
                if (response.success) {
                    this.loadItems();
                }
            });
        },
        closeEditor() {
            this.$refs.editor.hide();
        },
        openCategoriesEditor() {
            this.categoriesService.get({
                fields: 'id,name,parent,position',
                order: 'position',
                size: 9999
            }).then(response => {
                this.categoriesEditorItems = response.data;
            });
            this.$refs.categoriesEditor.show();
        },
        closeCategoriesEditor() {
            this.$refs.categoriesEditor.hide();
        },
        saveCategories(categories) {
            this.categoriesService.save(categories).then(response => {
                if (response.success) {
                    this.categoriesService.load();
                    this.closeCategoriesEditor();
                }
            })
        },
        selectEditorCategory(category) {
            this.editor.category = category;
        },
        changeFilterTags(tags) {
            this.listing.tags = tags;
        },
        changeQuery(query) {
            this.listing.query = query;
        },
    },
    computed: {
        params() {
            let params = {
                page: this.listing.page,
                filters: {
                    'catalog.category': this.listing.category
                }
            };
            if (this.tagsId.length) {
                params.filters['catalog_tag.tag_id'] = {
                    operator: 'IN',
                    value: this.tagsId
                }
            }
            if (this.listing.query) {
                params.filters['catalog.name'] = {
                    operator: 'LIKE',
                    value: '%' + this.listing.query + '%'
                }
            }

            return params;
        },
        tagsId() {
            const ids = [];
            if (this.listing.tags) {
                this.listing.tags.forEach(tag => {
                    ids.push(tag.id);
                })
            }
            return ids;
        },
        additionalCategories() {
            return [
                {
                    id: 'no-category',
                    name: 'Без категории'
                }
            ];
        }
    },
    mounted() {
        this.loadItems();
    },
    data() {
        return {
            categoriesService: CategoriesService,
            catalogService: CatalogService,
            loading: true,
            listing: {
                fields: [{
                    key: 'name',
                    label: 'Название товара'
                }],
                pagination: {},
                items: [],
                page: 1,
                category: 0,
                tags: [],
                query: '',
            },
            editor: {
                id: null,
                title: '',
                item: {},
                stores: [],
                options: [],
                prices: [],
                errors: []
            },
            categoriesEditorItems: []
        }
    }
}
</script>