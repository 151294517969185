<template>
    <autocomplete
        v-model="tag"
        label="Теги"
        placeholder="Поиск тега"
        item-label="name"
        :items="tags"
        :multiple="true"
        @async="autocomplete"
    >
    </autocomplete>
</template>

<script>
import { DictionariesService } from "../../../services/dictionaries.service";
import Autocomplete from "../Autocomplete";

export default {
    name: "AutocompleteTags",
    components: {Autocomplete},
    props: {
        modelValue: {
            type: [Object, Array, Number, null],
        },
    },
    methods: {
        autocomplete(value) {
            this.service.key = 'tags';
            this.service.autocomplete({
                filters: {
                    query: value
                }
            }).then(response => {
                if (response.data) {
                    this.tags = response.data;
                }
            })
        },
    },
    watch: {
        modelValue(value){
            this.tag = value;
        },
        tag(value) {
            this.$emit('update:modelValue', value);
        }
    },
    emits: ['update:modelValue', 'selectItem'],
    data() {
        return {
            service: DictionariesService,
            tags: [],
            tag: null,
        }
    }
}
</script>
