<template>
    <div class="editor">
        <div class="head">
            <div class="title">Редактирование категорий</div>
        </div>
        <div class="body">
            <div class="content">
                <categories-editor-tree
                    :items="items"
                    @remove="removeItem"
                    @change="changeItems"
                ></categories-editor-tree>
            </div>
        </div>
        <div class="foot">
            <div class="button-box" @click="save">Сохранить</div>
            <div class="button-box grey" @click="close">Закрыть</div>
        </div>
    </div>
</template>

<script>
import CategoriesEditorTree from "./CategoriesEditorTree";

export default {
    name: "CategoriesEditor",
    components: {CategoriesEditorTree},
    props: {
        items: Array,
    },
    methods: {
        close() {
            this.$emit('close');
        },
        save() {
            this.$emit('save', {
                items: this.finalItems,
                remove: this.removeItems
            });
        },
        changeItems(items) {
            this.finalItems = items;
        },
        removeItem(id) {
            this.removeItems.push(id);
        }
    },
    data() {
        return {
            finalItems: [],
            removeItems: []
        }
    }
}
</script>