<template>
    <editor
        :id="id"
        :item="data"
        @save="saveItem"
    >
        <template v-slot:head>
            <input-box
                label="Название"
                :error="checkError('name')"
                v-model="data['name']"
            ></input-box>
        </template>
        <template v-slot>
            <editor-tab title="Информация">
                <div class="info">
                    <div class="fields">
                        <div class="field">
                            <input-box
                                label="Название для сайта"
                                v-model="data['display_name']"
                                :error="checkError('display_name')"
                            ></input-box>
                        </div>
                        <div class="field">
                            <autocomplete-tags
                                v-model="data['tags']"
                            ></autocomplete-tags>
                        </div>
                        <div class="field">
                            <input-box
                                label="Алиас"
                                v-model="data['alias']"
                                :error="checkError('alias')"
                            ></input-box>
                        </div>
                        <div class="field">
                            <input-box
                                label="Артикул"
                                v-model="data['article']"
                                :error="checkError('article')"
                            ></input-box>
                        </div>
                        <div class="field">
                            <input-box
                                label="GUID"
                                v-model="data['guid']"
                                :error="checkError('guid')"
                            ></input-box>
                        </div>
                        <div class="field">
                            <input-box
                                label="Описание"
                                v-model="data['description']"
                                :error="checkError('description')"
                            ></input-box>
                        </div>
                        <div class="field">
                            <check-box
                                label="Выводить"
                                v-model="data['display']"
                            ></check-box>
                        </div>
                    </div>
                    <div class="categories">
                        <categories-tree
                            :items="categoriesService.state"
                            :default-item="data['category']"
                            @selectCategory="selectCategory"
                        ></categories-tree>
                    </div>
                </div>
            </editor-tab>
            <editor-tab title="Склады">
                <div class="stores">
                    <div class="fields content">
                        <div v-for="store in stores" :key="store.id" class="field">
                            <div class="label">{{ store.name }}</div>
                            <counter v-model="data.stores[store.id]"></counter>
                        </div>
                    </div>
                </div>
            </editor-tab>
            <editor-tab title="Цены">
                <div class="prices">
                    <div class="fields content">
                        <div v-for="price in prices" :key="price.id" class="field">
                            <input-box
                                :label="price.name"
                                v-model="data.prices[price.id]"
                            ></input-box>
                        </div>
                    </div>
                </div>
            </editor-tab>
            <editor-tab title="Характеристики">
                <div class="options">
                    <div class="fields content">
                        <div v-for="option in options" :key="option.id" class="field">
                            <input-box
                                :label="option.name"
                                v-model="data.options[option.id]"
                            ></input-box>
                        </div>
                    </div>
                </div>
            </editor-tab>
            <editor-tab title="Изображения">
                <div class="options">
                    <div class="content">
                        <upload
                            @upload="uploadImages"
                            @remove="removeImages"
                            :val="data['images']"
                        ></upload>
                    </div>
                </div>
            </editor-tab>
        </template>
    </editor>
</template>

<script>
import { CategoriesService } from "../../services/categories.service";
import { CatalogService } from "../../services/catalog.service";
import CategoriesTree from "./CategoriesTree";
import Upload from "../Upload";
import EditorTab from "../editor/EditorTab";
import InputBox from "../fields/InputBox";
import Counter from "../fields/Counter";
import Editor from "../editor/Editor";
import AutocompleteTags from "../fields/autocomplete/AutocompleteTags";
import CheckBox from "../fields/CheckBox";

export default {
    name: "CatalogEditor",
    components: {CheckBox, AutocompleteTags, Editor, CategoriesTree, EditorTab, Upload, Counter, InputBox},
    props: {
        id: [Number, null],
        title: String,
        item: Object,
        categories: {
            type: Array,
            default: () => []
        },
        stores: {
            type: Array,
            default: () => []
        },
        prices: {
            type: Array,
            default: () => []
        },
        options: {
            type: Array,
            default: () => []
        }
    },
    methods: {
        saveItem(response) {
            this.hasError = [];
            this.data['id'] = this.id;
            this.catalogService
                .save(response)
                .then(response => {
                    if (response.success === true) {
                        this.data['id'] = null;
                        this.$emit('itemSaved');
                    } else {
                        this.errors = response.errors;
                    }
                });
        },
        selectCategory(category) {
            this.data.category = category;
        },
        checkError(key) {
            return !!this.errors.find(error => error.key === key);
        },
        uploadImages(images) {
            this.data.images = images;
        },
        removeImages(images) {
            this.data.removeImages = images;
        },
        changeTab(tab) {
            this.currentTab = tab;
        },
    },
    watch: {
        item(value) {
            this.data = value;
            this.errors = [];
        }
    },
    data() {
        return {
            catalogService: CatalogService,
            categoriesService: CategoriesService,
            data: {
                category: 0,
                name: '',
                stores: {},
                prices: {},
                options: {},
                images: []
            },
            hasError: [],
            errors: []
        }
    }
}
</script>
