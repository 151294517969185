<template>
    <div v-if="init">

    </div>
    <div
        v-for="item in getItemsByParent(parent)"
        :key="item.id"
        :class="getItemClasses(item.id)"
        class="level"
    >
        <div class="item" :class="{selected: item.id === selected}">
            <span class="icon" @click="openItem(item.id)"></span>
            <span class="title" @click="selectCategory(item.id)">{{ item.name }}</span>
        </div>
        <categories-tree
            v-if="getItemsByParent(item.id).length"
            :default-item="selected"
            :init="false"
            :items="items"
            :parent="item.id"
            :editable="editable"
            @selectCategory="selectCategory"
        ></categories-tree>
    </div>
    <div
        v-for="link in additionalLinks"
        :key="link.id"
        :class="getItemClasses(link.id)"
        class="level"
    >
        <div class="item" :class="{selected: link.id === selected}">
            <span class="icon" @click="openItem(link.id)"></span>
            <span class="title" @click="selectCategory(link.id)">{{ link.name }}</span>
        </div>
    </div>
</template>

<script>
export default {
    name: "CategoriesTree",
    emits: ['selectCategory'],
    props: {
        opened: {
            type: Boolean,
            default: false
        },
        init: {
            type: Boolean,
            default: true
        },
        parent: {
            type: Number,
            default: 0
        },
        items: {
            type: Array
        },
        defaultItem: {
            type: [Number, String],
            default: 0
        },
        editable: {
            type: Boolean,
            default: false
        },
        additionalLinks: {
            type: Array,
            default: () => []
        }
    },
    methods: {
        getItemsByParent(parent) {
            return this.items
                ? this.items.filter(item => item.parent === parent)
                : [];
        },
        getItemClasses(id) {
            return {
                'has-child': !!this.getItemsByParent(id).length,
                'open': !!this.open[id]
            }
        },
        selectCategory(id) {
            this.selected = id;
            this.$emit('selectCategory', id);
        },
        openItem(id) {
            this.open[id] = !this.open[id];
        }
    },
    watch: {
        defaultItem(value) {
            this.selected = value;
        }
    },
    data() {
        return {
            open: [],
            selected: this.defaultItem
        }
    },
    created() {
        this.getItemsByParent(this.parent).map(function (item) {
            this.open[item.id] = this.opened;
        }.bind(this));
    }
}
</script>

<style scoped>

</style>